<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Preloader from "@/components/preloader";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pl";
import Multiselect from "vue-multiselect";
import vue2Dropzone from "vue2-dropzone";
import {
  required,
  maxLength,
  minLength,
  numeric,
  requiredIf,
  helpers,
} from "vuelidate/lib/validators";
import { dataPrepaidCardsStatus } from "@/data/data-prepaid-cards-status";
import Repository from "@/app/repository/repository-factory";
import Client from "@/app/repository/axios-client";
import { dataPrepaidCardsType } from "@/data/data-prepaid-card-type";
import { dataPrepaidCardsKindOfCard } from "@/data/data-prepaid-cards-kind-of-card";
import { roleService } from "@/app/service/roleService";

const alphaCapNum = helpers.regex(
  "alphaCapNum",
  /^[A-Z0-9]*$/
);

const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");
const PaymentBankRepository = Repository.get("PaymentBankRepository");
const PaymentPrepaidCardsRepository = Repository.get(
  "PaymentPrepaidCardsRepository"
);
const AttachmentRepository = Repository.get("AttachmentRepository");

/**
 * Dodawanie karty
 */
export default {
  page: {
    title: "Dodawanie karty",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
    Multiselect,
    DatePicker,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      header: {
        title: "Dodaj kartę",
        items: [
          {
            text: "Strona główna",
            to: {
              name: "home",
            },
          },
          {
            text: "Lista kart",
            to: {
              name: "Lista kart",
            },
          },
          {
            text: "Dodaj kartę",
          },
        ],
      },
      form: {
        companyId: "",
        bankId: null,
        cardNumber: null,
        nameOfCardholder: "",
        expiryDate: null,
        spendingLimit: "",
        openCardDate: null,
        closeCardDate: null,
        cardholderNumber: "",
        bankAccountNumber: "",
        status: null,
        cardType: null,
        kindOfCard: null,
        // relatedCard: null,
        additionalInfo: "",
        attachments: [],
      },
      dropzoneOptions: {
        url: AttachmentRepository.getResourcePath(),
        thumbnailWidth: 150,
        paramName: "file",
        maxFilesize: 10, // 10MB
        addRemoveLinks: true,
        dictRemoveFile: "✕",
        headers: {
          Authorization: Client.defaults.headers.common["Authorization"],
          "Cache-Control": null,
          "X-Requested-With": null,
        },
      },
      preloader: true,
      disabledBtn: false,
      dataPrepaidCardsStatus: dataPrepaidCardsStatus,
      dataPrepaidCardsType: dataPrepaidCardsType,
      dataPrepaidCardsKindOfCard: dataPrepaidCardsKindOfCard,
      companySelectOptions: [],
      bankSelectOptions: [],
      statusSelectOptions: [],
      cardTypeSelectOptions: [],
      kindOfCardSelectOptions: [],
      prepaidCardsSelectOptions: [],
    };
  },
  validations: {
    form: {
      companyId: {
        required,
      },
      bankId: {
        required,
      },
      cardNumber: {
        maxLength: maxLength(16),
        minLength: minLength(13),
        numeric,
        validCardNumber: (value) => {
          if (value !== null && value !== "") {
            let i = 0;
            let sum = 0;
            let digit = 0;
            let card = value.split("");
            let cardLength = card.length;
            let parity = cardLength % 2;

            for (i = 0; i < cardLength; i++) {
              digit = parseInt(card[i]);
              if (i % 2 == parity) {
                digit *= 2;
              }
              if (digit > 9) {
                digit -= 9;
              }
              sum += digit;
            }
            return sum % 10 === 0 ? true : false;
          } else if (!helpers.req(value)) {
            return true;
          }
        },
      },
      nameOfCardholder: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(2),
      },
      expiryDate: {
        required: false,
      },
      bankAccountNumber: {
        required,
        // maxLength: maxLength(26),
        // minLength: minLength(26),
        // numeric,
        maxLength (value) {
          return maxLength(this.isCountryCodeOtherThanPL ? 50 : 26)(value)
        },
        minLength (value) {
          return minLength(this.isCountryCodeOtherThanPL ? 6 : 26)(value)
        },
        numeric (value) {
          if (!this.isCountryCodeOtherThanPL) {
            return numeric(value)
          }
          return true
        },
        alphaCapNum (value) {
          const regex = /^[A-Z0-9]*$/;
          if (this.isCountryCodeOtherThanPL) {
            return regex.test(value)
          }
          return true
        },
      },
      status: {
        required,
      },
      spendingLimit: {
        required,
      },
      openCardDate: {
        required: false,
      },
      closeCardDate: {
        required: false,
      },
      cardholderNumber: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(1),
        numeric,
      },
      cardType: {
        required,
      },
      kindOfCard: {
        required,
      },
      // relatedCard: {
      //   required: requiredIf(function () {
      //     return this.form.kindOfCard &&
      //       (this.form.kindOfCard.id == 2 || this.form.kindOfCard.id == 3)
      //       ? true
      //       : false;
      //   }),
      // },
      additionalInfo: {
        maxLength: maxLength(500),
      },
      attachments: [],
    },
  },
  created() {
    this.preloader = true;
    this.getData();
  },
  methods: {
    async getCompanies() {
      try {
        const { data } = await PaymentCompanyRepository.getAll();
        this.companySelectOptions = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getBanks() {
      try {
        const { data } = await PaymentBankRepository.getAll();
        this.bankSelectOptions = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getPrepaidCards() {
      try {
        const { data } = await PaymentPrepaidCardsRepository.getAll();
        this.prepaidCardsSelectOptions = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        await Promise.all([
          this.getCompanies(),
          this.getBanks(),
          this.getPrepaidCards(),
        ]);
        this.preloader = false;
      } catch (error) {
        console.log(error);
      }
    },
    addPrepaidCard() {
      this.formSubmit();

      if (this.$v.$error === true) {
        return false;
      }

      let payload = Object.assign({}, this.form);

      payload.bankId = this.form.bankId.bankId;
      payload.companyId = this.form.companyId.companyId;
      payload.status = this.form.status.id;
      payload.cardType = this.form.cardType.id;
      payload.kindOfCard = this.form.kindOfCard.id;

      // if (
      //   this.form.kindOfCard &&
      //   (this.form.kindOfCard.id == 2 || this.form.kindOfCard.id == 3)
      // ) {
      //   payload.relatedPrepaidCardId = this.form.relatedCard.prepaidCardId;
      // } else {
      payload.relatedPrepaidCardId = null;
      // }

      this.disabledBtn = true;
      this.preloader = true;

      PaymentPrepaidCardsRepository.create(payload)
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Karta została dodana!",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({
                name: "Lista kart",
              });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    formSubmit(e) {
      this.$v.$touch();
    },
    statusLabel({ id, name }) {
      return `${id} ${name}`;
    },
    companyLabel({ name, nip, address, companyId }) {
      return `${name} ${nip} ${address} ${companyId}`;
    },
    bankLabel({ name, shortName, bankId }) {
      return `${name} ${shortName} ${bankId}`;
    },
    cardLabel({
      cardNumber,
      nameOfCardholder,
      bankAccountNumber,
      cardholderNumber,
    }) {
      return `${cardNumber} ${nameOfCardholder} ${bankAccountNumber} ${cardholderNumber}`;
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    dropzoneAfterSuccess(file, response) {
      this.form.attachments.push(response.uuid);
      file["tempUploadUuid"] = response.uuid;
    },
    dropzoneAfterRemove(file, error, xhr) {
      for (let i = 0; i < this.form.attachments.length; i++) {
        if (!this.form.attachments.attachmentId) {
          if (
            file.tempUploadUuid &&
            this.form.attachments[i] === file.tempUploadUuid
          ) {
            this.form.attachments.splice(i, 1);
          }
        }
      }
    },
    dropzoneSendingEvent(file, xhr, formData) {
      formData.append("attachmentTypeOfObject", 1);
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
    isProductAdministrator() {
      return roleService.isProductAdministrator();
    },
    isCountryCodeOtherThanPL() {
      if (this.form.bankId) {
        return this.form.bankId.countryCode != 'PL' ? true : false;
      }
      return false;
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="header.title" :items="header.items" />

    <b-form @submit.prevent="formSubmit" v-if="isAdmin || isProductAdministrator">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-sm">
            <b-card-header>
              <div class="row align-items-center">
                <div class="col">Formularz dodawania karty</div>
                <div class="col text-right">
                  <b-link class="btn btn-sm btn-warning" :to="{ name: 'Lista kart' }">Anuluj</b-link>
                </div>
              </div>
            </b-card-header>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="company">
                      Wybierz firmę
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="company" v-model.trim="form.companyId" :options="companySelectOptions" :custom-label="companyLabel" :class="{ 'is-invalid': $v.form.companyId.$error, 'is-valid': !$v.form.companyId.$invalid && !$v.form.companyId.$error }" track-by="companyId" name="company" placeholder="Wybierz firmę z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small">{{ props.option.address }} / {{ props.option.sourceOfData ? props.option.sourceOfData.name : "" }} {{ props.option.businessUnitCode }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.address }} / {{ props.option.sourceOfData ? props.option.sourceOfData.name : "" }} {{ props.option.businessUnitCode }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <div class="form-group">
                    <label for="bankId">
                      Wybierz bank
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="bankId" v-model.trim="form.bankId" :options="bankSelectOptions" :custom-label="bankLabel" :class="{ 'is-invalid': $v.form.bankId.$error, 'is-valid': !$v.form.bankId.$invalid && !$v.form.bankId.$error }" track-by="bankId" name="bankId" placeholder="Wybierz bank z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.shortName }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <div class="form-group">
                    <label for="providerAccountField">
                      Numer rachunku karty
                      <em class="text-danger">*</em>
                    </label>
                    <input id="providerAccountField" v-model.trim="form.bankAccountNumber" @input="$v.form.bankAccountNumber.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.bankAccountNumber.$error, 'is-valid': !$v.form.bankAccountNumber.$invalid && !$v.form.bankAccountNumber.$error }" type="text" name="providerAccountField" placeholder="Wpisz numer rachunku karty" value required />
                    <div v-if="$v.form.bankAccountNumber.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.minLength">Zbyt mała ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.numeric">Pole przyjmuje wyłącznie wartości liczbowe!</span>
                      <span class="d-block" v-if="$v.form.bankAccountNumber.$error && !$v.form.bankAccountNumber.alphaCapNum">Pole przyjmuje wyłącznie wartości liczbowe oraz duże litery bez polskich znaków!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="card-type">
                      Wybierz typ karty
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="card-type" v-model.trim="form.cardType" :options="dataPrepaidCardsType" :custom-label="statusLabel" :class="{ 'is-invalid': $v.form.cardType.$error, 'is-valid': !$v.form.cardType.$invalid && !$v.form.cardType.$error }" track-by="id" name="cardType" placeholder="Wybierz typ karty z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.shortName }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <div class="form-group">
                    <label for="nameOfCardholder">
                      Imię i nazwisko właściciela karty
                      <em class="text-danger">*</em>
                    </label>
                    <input id="nameOfCardholder" v-model.trim="form.nameOfCardholder" @input="$v.form.nameOfCardholder.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.nameOfCardholder.$error, 'is-valid': !$v.form.nameOfCardholder.$invalid && !$v.form.nameOfCardholder.$error }" type="text" name="nameOfCardholder" placeholder="Wpisz imię i nazwisko" value required />
                    <div v-if="$v.form.nameOfCardholder.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.nameOfCardholder.$error && !$v.form.nameOfCardholder.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.nameOfCardholder.$error && !$v.form.nameOfCardholder.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.nameOfCardholder.$error && !$v.form.nameOfCardholder.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="cardholderNumber">
                      Identyfikator użytkownika karty
                      <em class="text-danger">*</em>
                    </label>
                    <input id="cardholderNumber" v-model.trim="form.cardholderNumber" @input="$v.form.cardholderNumber.$touch()" v-mask="'##########'" class="form-control" :class="{ 'is-invalid': $v.form.cardholderNumber.$error, 'is-valid': !$v.form.cardholderNumber.$invalid && !$v.form.cardholderNumber.$error }" type="text" name="cardholderNumber" placeholder="Wpisz numer dostawcy pracownika lub numer NIP" value required />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Numer dostawcy pracownika lub numer NIP</small>
                    <div v-if="$v.form.cardholderNumber.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.cardholderNumber.$error && !$v.form.cardholderNumber.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.cardholderNumber.$error && !$v.form.cardholderNumber.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.cardholderNumber.$error && !$v.form.cardholderNumber.minLength">Zbyt mała ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.cardholderNumber.$error && !$v.form.cardholderNumber.numeric">Pole przyjmuje wyłącznie wartości liczbowe!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="cardNumber">
                      Numer karty
                    </label>
                    <input id="cardNumber" v-model.trim="form.cardNumber" @input="$v.form.cardNumber.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.cardNumber.$error, 'is-valid': !$v.form.cardNumber.$invalid && !$v.form.cardNumber.$error }" type="text" name="cardNumber" placeholder="Wpisz numer karty" value />
                    <div v-if="$v.form.cardNumber.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.cardNumber.$error && !$v.form.cardNumber.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.cardNumber.$error && !$v.form.cardNumber.minLength">Zbyt mała ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.cardNumber.$error && !$v.form.cardNumber.numeric">Pole przyjmuje wyłącznie wartości liczbowe!</span>
                      <span class="d-block" v-if="$v.form.cardNumber.$error && !$v.form.cardNumber.validCardNumber">Niepoprawny numer karty!</span>
                    </div>
                  </div>

                </div>
                <div class="col-md-5 offset-md-1">

                  <div class="form-group">
                    <label for="expiryDate">
                      Data wygaśnięcia karty
                    </label>
                    <date-picker id="expiryDate" v-model="form.expiryDate" type="month" value-type="format" format="MM/YY" :editable="false" :first-day-of-week="1" :disabled-date="disabledBeforeToday" @input="$v.form.expiryDate.$touch()" :class="{ 'is-invalid': $v.form.expiryDate.$error, 'is-valid': !$v.form.expiryDate.$invalid && !$v.form.expiryDate.$error }" lang="pl" placeholder="Data wygaśnięcia karty MM/YY" />
                  </div>

                  <div class="form-group">
                    <label for="spendingLimit">
                      Limit karty
                      <em class="text-danger">*</em>
                    </label>
                    <input id="spendingLimit" v-model.trim="form.spendingLimit" @input="$v.form.spendingLimit.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.spendingLimit.$error, 'is-valid': !$v.form.spendingLimit.$invalid && !$v.form.spendingLimit.$error }" type="text" name="spendingLimit" placeholder="Wpisz limit karty" value required />
                    <div v-if="$v.form.spendingLimit.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.spendingLimit.$error && !$v.form.spendingLimit.required">Pole jest wymagane!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="openCardDate">
                      Data otwarcia karty
                    </label>
                    <date-picker id="openCardDate" v-model="form.openCardDate" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" :disabled-date="disabledBeforeToday" @input="$v.form.openCardDate.$touch()" :class="{ 'is-invalid': $v.form.openCardDate.$error, 'is-valid': !$v.form.openCardDate.$invalid && !$v.form.openCardDate.$error }" lang="pl" placeholder="Data otwarcia karty YYYY-MM-DD" />
                  </div>

                  <div class="form-group">
                    <label for="closeCardDate">
                      Data zamknięcia karty
                    </label>
                    <date-picker id="closeCardDate" v-model="form.closeCardDate" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" :disabled-date="disabledBeforeToday" @input="$v.form.closeCardDate.$touch()" :class="{ 'is-invalid': $v.form.closeCardDate.$error, 'is-valid': !$v.form.closeCardDate.$invalid && !$v.form.closeCardDate.$error }" lang="pl" placeholder="Data zamknięcia karty YYYY-MM-DD" />
                  </div>

                  <div class="form-group">
                    <label for="card-kind-of-card">
                      Wybierz rodzaj karty
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="card-kind-of-card" v-model.trim="form.kindOfCard" :options="dataPrepaidCardsKindOfCard" :custom-label="statusLabel" :class="{ 'is-invalid': $v.form.kindOfCard.$error, 'is-valid': !$v.form.kindOfCard.$invalid && !$v.form.kindOfCard.$error }" track-by="id" name="kindOfCard" placeholder="Wybierz rodzaj karty z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <!--
                  <div class="form-group" v-if="form.kindOfCard && (form.kindOfCard.id == 2 || form.kindOfCard.id == 3)">
                    <label for="card-related-card">
                      Wybierz powiązaną kartę
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="card-related-card" v-model.trim="form.relatedCard" :options="prepaidCardsSelectOptions" :custom-label="cardLabel" :class="{ 'is-invalid': $v.form.relatedCard.$error, 'is-valid': !$v.form.relatedCard.$invalid && !$v.form.relatedCard.$error }" track-by="prepaidCardId" name="relatedCard" placeholder="Wybierz kartę z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.cardNumber }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.nameOfCardholder }} {{ props.option.expiryDate }}</span>
                          <br />
                          <span class="option__small small text-dark">Nr konta: {{ props.option.bankAccountNumber }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.cardNumber }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.nameOfCardholder }} {{ props.option.expiryDate }}</span>
                          <br />
                          <span class="option__small small text-dark">Nr konta: {{ props.option.bankAccountNumber }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                  -->

                  <div class="form-group">
                    <label for="card-status">
                      Wybierz status karty
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="card-status" v-model.trim="form.status" :options="dataPrepaidCardsStatus" :custom-label="statusLabel" :class="{ 'is-invalid': $v.form.status.$error, 'is-valid': !$v.form.status.$invalid && !$v.form.status.$error }" track-by="id" name="status" placeholder="Wybierz status karty z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.shortName }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <div class="form-group">
                    <label for="transferAdditionalInfoField">Uwagi</label>
                    <textarea id="transferVatAmountField" v-model.trim="form.additionalInfo" @input="$v.form.additionalInfo.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.additionalInfo.$error, 'is-valid': !$v.form.additionalInfo.$invalid && !$v.form.additionalInfo.$error }" type="text" placeholder="Wpisz dodatkowe uwagi" value></textarea>
                    <div v-if="$v.form.additionalInfo.$invalid" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.additionalInfo.$invalid && !$v.form.additionalInfo.maxLength">Przekroczona dozwolona ilość znaków!</span>
                    </div>
                  </div>

                </div>
                <div class="col-md-11">
                  <div class="form-group">
                    <label for="dropzone">Załączniki</label>
                    <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" @vdropzone-success="dropzoneAfterSuccess" @vdropzone-sending="dropzoneSendingEvent" @vdropzone-removed-file="dropzoneAfterRemove" :useCustomSlot=true>
                      <div class="dropzone-custom-content dz-message">
                        upuść pliki tutaj lub kliknij, aby przesłać
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
                <div class="col-md-11">
                  <button class="btn btn-dark" v-on:click="addPrepaidCard" :disabled="$v.form.$invalid || disabledBtn" type="submit">Dodaj kartę</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Layout>
</template>
