// src\Payment\Domain\ValueObject\PrepaidCardStatus.php
const dataPrepaidCardsStatus = [
  {
    "id": 1,
    "name": "Gotowa do zasilenia",
    "value": "Gotowa do zasilenia",
    "text": "Gotowa do zasilenia",
  },
  {
    "id": 2,
    "name": "Brak możliwości zasilenia",
    "value": "Brak możliwości zasilenia",
    "text": "Brak możliwości zasilenia",
  },
];

export { dataPrepaidCardsStatus };
