// src\Payment\Domain\ValueObject\PrepaidCardType.php
const dataPrepaidCardsType = [
  {
    "id": 1,
    "name": "Przedpłacona",
    "value": "Przedpłacona",
    "text": "Przedpłacona",
  },
  {
    "id": 2,
    "name": "Debetowa",
    "value": "Debetowa",
    "text": "Debetowa",
  },
  {
    "id": 3,
    "name": "Kredytowa",
    "value": "Kredytowa",
    "text": "Kredytowa",
  },
];

const dataTypeOfBoolean = [
  {
    id: 1,
    name: "TAK",
    value: 1,
    bool: true,
    text: "TAK",
    description: "Tak",
  },
  {
    id: 2,
    name: "NIE",
    value: 0,
    bool: false,
    text: "NIE",
    description: "Nie",
  },
];

export { dataPrepaidCardsType };
export { dataTypeOfBoolean }