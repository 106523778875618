// src\Payment\Domain\ValueObject\PrepaidCardKindOfCard.php
const dataPrepaidCardsKindOfCard = [
  {
    "id": 1,
    "name": "Nowa",
    "value": "Nowa",
    "text": "Nowa",
  },
  {
    "id": 2,
    "name": "Wznowienie",
    "value": "Wznowienie",
    "text": "Wznowienie",
  },
  {
    "id": 3,
    "name": "Duplikat",
    "value": "Duplikat",
    "text": "Duplikat",
  },
];

export { dataPrepaidCardsKindOfCard };